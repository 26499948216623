import React from 'react';
import { Button } from './components/ui/button';
import { FaGoogle, FaApple, FaGithub } from 'react-icons/fa';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#352F44] to-[#2A2438] flex flex-col justify-center items-center text-[#DBD8E3] p-4">
      <div className="max-w-4xl w-full bg-[#5C5470]/10 backdrop-blur-lg rounded-xl shadow-2xl p-8 space-y-8">
        <div className="flex justify-center">
          {/* Logo placement */}
          {/* <img src="./images/archivist-wide-logo.png" alt="The Archivist Logo" className="w-64 h-auto hover:scale-105 transition-transform duration-300" /> */}
        </div>
        
        <h1 className="text-5xl md:text-7xl font-extrabold text-center tracking-tight">
          ArchivistAI
        </h1>
        <h2 className="text-2xl md:text-2xl font-extrabold text-center tracking-tight">
          coming soon...
        </h2>
        
        <p className="text-2xl text-center max-w-2xl mx-auto leading-relaxed">
          Transcribe handwritten or typed documents in multiple languages with ease.
          Simply take a picture, and let The Archivist do the rest.
        </p>
        
        <div className="space-y-6">
          <h2 className="text-3xl font-semibold text-center">Key Features</h2>
          <ul className="list-disc list-inside space-y-4 text-xl">
            <li>Multi-language support for transcription</li>
            <li>Handwritten and typed document recognition</li>
            <li>Easy-to-use interface for quick uploads</li>
            <li>Secure storage and organization of your documents</li>
          </ul>
        </div>
        
        {/* <div className="flex justify-center space-x-4 pt-6">
          <a
            href="http://localhost:3000/login"
            className="bg-[#DBD8E3] hover:bg-[#BEBAC6] text-[#2A2438] px-6 py-3 rounded-full text-lg shadow-md transition-all duration-300 ease-in-out"
          >
            Get Started
          </a>
          <a
            href="/about"
            className="bg-[#DBD8E3] hover:bg-[#BEBAC6] text-[#2A2438] px-6 py-3 rounded-full text-lg shadow-md transition-all duration-300 ease-in-out"
          >
            Learn More
          </a>
        </div> */}
      
      </div>
    </div>
  );
};

export default LandingPage;
