export function Card({ children, className, ...props }) {
    return <div className={`bg-white shadow rounded-lg ${className}`} {...props}>{children}</div>;
  }
  export function CardHeader({ children, className, ...props }) {
    return <div className={`px-4 py-5 border-b border-gray-200 sm:px-6 ${className}`} {...props}>{children}</div>;
  }
  export function CardContent({ children, className, ...props }) {
    return <div className={`px-4 py-5 sm:p-6 ${className}`} {...props}>{children}</div>;
  }
  export function CardTitle({ children, className, ...props }) {
    return <h3 className={`text-lg leading-6 font-medium text-gray-900 ${className}`} {...props}>{children}</h3>;
  }