import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { supabase } from './supabaseClient';

const ProjectPage = () => {
  const [projects, setProjects] = useState([]);
  const [newProjectName, setNewProjectName] = useState('');
  const [alert, setAlert] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = useCallback(async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  }, []);

  const fetchProjects = useCallback(async () => {
    if (!user) return;

    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', user.id);
      
      if (error) throw error;
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setAlert({ type: 'error', message: 'Failed to fetch projects.' });
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (user) {
      fetchProjects();
    }
  }, [user, fetchProjects]);

  const handleCreateProject = async () => {
    if (!newProjectName.trim()) {
      setAlert({ type: 'error', message: 'Project name cannot be empty.' });
      return;
    }

    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('projects')
        .insert({ name: newProjectName.trim(), user_id: user.id })
        .select();

      if (error) throw error;

      setProjects(prevProjects => [...prevProjects, data[0]]);
      setNewProjectName('');
      setAlert({ type: 'success', message: 'Project created successfully.' });
    } catch (error) {
      console.error('Error creating project:', error);
      setAlert({ type: 'error', message: 'Failed to create project.' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProject = async (projectId) => {
    if (window.confirm('Are you sure you want to delete this project? This action cannot be reversed.')) {
      setIsLoading(true);
      try {
        const { error } = await supabase
          .from('projects')
          .delete()
          .eq('id', projectId)
          .eq('user_id', user.id);

        if (error) throw error;

        setProjects(prevProjects => prevProjects.filter(project => project.id !== projectId));
        setAlert({ type: 'success', message: 'Project deleted successfully.' });
      } catch (error) {
        console.error('Error deleting project:', error);
        setAlert({ type: 'error', message: 'Failed to delete project.' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="space-y-4">
      <h1 className="text-2xl font-bold">Project Management</h1>
      
      {alert && (
        <Alert variant={alert.type === 'error' ? 'destructive' : 'default'}>
          <AlertTitle>{alert.type === 'error' ? 'Error' : 'Success'}</AlertTitle>
          <AlertDescription>{alert.message}</AlertDescription>
        </Alert>
      )}

      <Card>
        <CardHeader>
          <CardTitle>Your Projects</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              {projects.map(project => (
                <div key={project.id} className="flex justify-between items-center">
                  <span>{project.name}</span>
                  <Button variant="destructive" onClick={() => handleDeleteProject(project.id)} disabled={isLoading}>Delete</Button>
                </div>
              ))}
              <div className="flex space-x-2">
                <Input 
                  value={newProjectName} 
                  onChange={(e) => setNewProjectName(e.target.value)}
                  placeholder="New project name"
                  disabled={isLoading}
                />
                <Button onClick={handleCreateProject} disabled={isLoading}>Create</Button>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ProjectPage;