import React from 'react';

export const Alert = ({ children, className, variant, ...props }) => (
  <div className={`p-4 border-l-4 ${variant === 'error' ? 'bg-red-100 border-red-500' : 'bg-blue-100 border-blue-500'} ${className}`} {...props}>
    {children}
  </div>
);

export const AlertTitle = ({ children, className, ...props }) => (
  <h3 className={`text-lg font-medium ${className}`} {...props}>{children}</h3>
);

export const AlertDescription = ({ children, className, ...props }) => (
  <div className={`mt-2 text-sm ${className}`} {...props}>{children}</div>
);