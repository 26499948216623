import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import AccountPage from './AccountPage';
import ProjectPage from './ProjectPage';
import DocumentPage from './DocumentPage';

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };

    fetchUser();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <nav className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/" className="text-xl font-bold text-indigo-600">ArchivistAI</Link>
                </div>
                {user && (
                  <div className="ml-6 flex space-x-8">
                    <Link to="/account" className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">Account</Link>
                    <Link to="/projects" className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">Projects</Link>
                    <Link to="/documents" className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">Documents</Link>
                  </div>
                )}
              </div>
              <div className="flex items-center">
                {user ? (
                  <button onClick={handleLogout} className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                    Log out
                  </button>
                ) : (
                  <Link to="/login" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                    Log in
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>

        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            {/* <Route path="/login" element={user ? <Navigate to="/account" /> : <LoginPage />} /> */}
            <Route path="/account" element={user ? <AccountPage /> : <Navigate to="/login" />} />
            <Route path="/projects" element={user ? <ProjectPage /> : <Navigate to="/login" />} />
            <Route path="/documents" element={user ? <DocumentPage /> : <Navigate to="/login" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;