import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { Textarea } from './components/ui/textarea';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select";
import { Label } from "./components/ui/label";
import { Upload, Trash2 } from 'lucide-react';
import { supabase } from './supabaseClient';

const DocumentPage = () => {
  const [projects, setProjects] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [transcription, setTranscription] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [alert, setAlert] = useState(null);
  const [user, setUser] = useState(null);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchProjects();
    }
  }, [user]);

  useEffect(() => {
    if (currentProject) {
      fetchDocuments();
    }
  }, [currentProject]);

  const fetchUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  };

  const fetchProjects = async () => {
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', user.id);
      
      if (error) throw error;
      setProjects(data);
      if (data.length > 0) setCurrentProject(data[0]);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setAlert({ type: 'error', message: 'Failed to fetch projects.' });
    }
  };

  const fetchDocuments = async () => {
    try {
      const { data, error } = await supabase
        .from('documents')
        .select('*')
        .eq('project_id', currentProject.id);
      
      if (error) throw error;
      setDocuments(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setAlert({ type: 'error', message: 'Failed to fetch documents.' });
    }
  };

  const handleProjectChange = (projectId) => {
    const project = projects.find(p => p.id === projectId);
    setCurrentProject(project);
    setCurrentDocument(null);
    setDocumentName('');
    setTranscription('');
    setImageUrl(null);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setAlert({ type: 'info', message: 'Uploading image...' });
        
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${currentProject.id}/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('documents')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        const { data: { publicUrl }, error: urlError } = supabase.storage
          .from('documents')
          .getPublicUrl(filePath);

        if (urlError) throw urlError;

        setImageUrl(publicUrl);
        setAlert({ type: 'success', message: 'Image uploaded successfully.' });
      } catch (error) {
        console.error('Error uploading image:', error);
        setAlert({ type: 'error', message: 'Failed to upload image: ' + error.message });
      }
    }
  };

  const handleTranscribe = async () => {
    if (!imageUrl) {
      setAlert({ type: 'error', message: 'Please upload an image first.' });
      return;
    }
  
    setIsTranscribing(true);
    setAlert({ type: 'info', message: 'Transcribing image...' });
  
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const formData = new FormData();
      formData.append('file', blob, 'image.jpg');
  
      const transcribeResponse = await fetch('http://0.0.0.0:8000/transcribe/', {
        method: 'POST',
        body: formData,
      });
  
      if (!transcribeResponse.ok) {
        throw new Error('Transcription failed');
      }
  
      const result = await transcribeResponse.json();
      if (result && result.transcription) {
        setTranscription(result.transcription);
        setAlert({ type: 'success', message: 'Transcription completed successfully.' });
      } else {
        throw new Error('Invalid transcription response');
      }
    } catch (error) {
      console.error('Error transcribing image:', error);
      setAlert({ type: 'error', message: 'Failed to transcribe image: ' + error.message });
    } finally {
      setIsTranscribing(false);
    }
  };

  const handleSaveDocument = async () => {
    if (!documentName || !transcription || !imageUrl) {
      setAlert({ type: 'error', message: 'Please fill in all fields and upload an image.' });
      return;
    }

    try {
      setAlert({ type: 'info', message: 'Saving document...' });
      
      const documentData = {
        project_id: currentProject.id,
        name: documentName,
        transcription: transcription,
        image_url: imageUrl
      };

      let result;
      if (currentDocument) {
        // Update existing document
        const { data, error } = await supabase
          .from('documents')
          .update(documentData)
          .eq('id', currentDocument.id)
          .select();
        
        if (error) throw error;
        result = data[0];
      } else {
        // Create new document
        const { data, error } = await supabase
          .from('documents')
          .insert(documentData)
          .select();

        if (error) throw error;
        result = data[0];
      }

      setDocuments(prevDocs => {
        const newDocs = prevDocs.filter(doc => doc.id !== result.id);
        return [...newDocs, result];
      });

      setCurrentDocument(null);
      setDocumentName('');
      setTranscription('');
      setImageUrl(null);
      setAlert({ type: 'success', message: `Document ${currentDocument ? 'updated' : 'created'} successfully.` });
    } catch (error) {
      console.error('Error saving document:', error);
      setAlert({ type: 'error', message: 'Failed to save document: ' + error.message });
    }
  };

  const handleEditDocument = (doc) => {
    setCurrentDocument(doc);
    setDocumentName(doc.name);
    setTranscription(doc.transcription);
    setImageUrl(doc.image_url);
  };

  const handleDeleteDocument = (doc) => {
    setDocumentToDelete(doc);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!documentToDelete) return;

    try {
      setAlert({ type: 'info', message: 'Deleting document...' });
      
      const { error } = await supabase
        .from('documents')
        .delete()
        .eq('id', documentToDelete.id);
      
      if (error) throw error;

      setDocuments(prevDocs => prevDocs.filter(doc => doc.id !== documentToDelete.id));
      
      if (currentDocument && currentDocument.id === documentToDelete.id) {
        setCurrentDocument(null);
        setDocumentName('');
        setTranscription('');
        setImageUrl(null);
      }

      setAlert({ type: 'success', message: 'Document deleted successfully.' });
    } catch (error) {
      console.error('Error deleting document:', error);
      setAlert({ type: 'error', message: 'Failed to delete document: ' + error.message });
    } finally {
      setDeleteModalOpen(false);
      setDocumentToDelete(null);
    }
  };

  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/4 space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>Project</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <Select onValueChange={handleProjectChange} value={currentProject?.id}>
              <SelectTrigger>
                <SelectValue placeholder="Select Project" />
              </SelectTrigger>
              <SelectContent>
                {projects.map(project => (
                  <SelectItem key={project.id} value={project.id}>{project.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </CardContent>
        </Card>
        {currentProject && (
          <Card>
            <CardHeader>
              <CardTitle>Documents</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
              {documents.map(doc => (
                <div key={doc.id} className="flex items-center space-x-2">
                  <Button
                    variant="outline"
                    className="w-full text-left justify-start"
                    onClick={() => handleEditDocument(doc)}
                  >
                    {doc.name}
                  </Button>
                  <Button
                    variant="destructive"
                    size="icon"
                    onClick={() => handleDeleteDocument(doc)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </CardContent>
          </Card>
        )}
      </div>
      <div className="w-full md:w-3/4 space-y-4">
        {alert && (
          <Alert variant={alert.type === 'error' ? 'destructive' : 'default'}>
            <AlertTitle>{alert.type === 'error' ? 'Error' : 'Success'}</AlertTitle>
            <AlertDescription>{alert.message}</AlertDescription>
          </Alert>
        )}
        <Card>
          <CardHeader>
            <CardTitle>{currentDocument ? 'Edit Document' : 'New Document'}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <Input 
              value={documentName} 
              onChange={(e) => setDocumentName(e.target.value)}
              placeholder="Document name"
            />
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2 space-y-2">
                <Label htmlFor="image-upload" className="cursor-pointer flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-4">
                  <Upload className="h-6 w-6 mr-2" />
                  {imageUrl ? 'Replace Image' : 'Upload Image'}
                </Label>
                <Input 
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageUpload}
                />
                {imageUrl && (
                  <div className="mt-2 h-96 bg-gray-100 rounded-lg flex items-center justify-center overflow-hidden">
                    <img 
                      src={imageUrl} 
                      alt="Uploaded document" 
                      className="max-w-full max-h-full object-contain"
                    />
                  </div>
                )}
                <Button 
                  onClick={handleTranscribe} 
                  disabled={!imageUrl || isTranscribing}
                  className="w-full mt-2"
                >
                  {isTranscribing ? 'Transcribing...' : 'Transcribe Image'}
                </Button>
              </div>
              <div className="w-full md:w-1/2">
                <Textarea 
                  value={transcription} 
                  onChange={(e) => setTranscription(e.target.value)}
                  placeholder="Enter transcription here..."
                  className="h-96 resize-none"
                />
              </div>
            </div>
            <Button onClick={handleSaveDocument} className="w-full">
              {currentDocument ? 'Update Document' : 'Create Document'}
            </Button>
          </CardContent>
        </Card>
      </div>

      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-4">Are you sure you want to delete this document? This action cannot be undone.</p>
            <div className="flex justify-end space-x-2">
              <Button variant="outline" onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
              <Button variant="destructive" onClick={confirmDelete}>Delete</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentPage;